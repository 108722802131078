import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import {
  FaCalendarDay,
  FaLaptop,
  FaRegClipboard,
  FaUserTie,
} from "react-icons/fa";
import Swiper from "react-id-swiper";
import styled from "styled-components";
import { Autoplay, Pagination } from "swiper";

import Columns from "../components/columns";
import Container from "../components/container";
import Hero from "../components/hero";
import Layout from "../components/layout";
import SEO from "../components/seo";
import TestimonialCard from "../components/TestimonialCard";
import Tile from "../components/Tile";
import heroImage from "../images/questy-producent-kompleksowych-rozwiazan-dla-biznesu.jpg";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import * as styles from "./index.module.css";
import Dots from "../components/dots";
import { Link } from "gatsby";

const params = {
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    dynamicBullets: false,
  },
  autoplay: {
    delay: 4000,
  },
};

export const query = graphql`
  {
    heroBg: file(
      relativePath: {
        eq: "questy-producent-kompleksowych-rozwiazan-dla-biznesu.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imgLaptopITel: file(relativePath: { eq: "mobilny-program-crm.png" }) {
      childImageSharp {
        gatsbyImageData(width: 800, layout: CONSTRAINED)
      }
    }
    imgHero: file(
      relativePath: { eq: "synergius-crm-program-crm-dla-firm.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 800, layout: FULL_WIDTH)
      }
    }
    imgSage: file(relativePath: { eq: "integracje/integracja-symfonia.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
    imgSubiekt: file(
      relativePath: { eq: "integracje/integracja-subiekt.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
    imgCDN: file(relativePath: { eq: "integracje/integracja-optima.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
    imgWapro: file(relativePath: { eq: "integracje/integracja-wapro.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
    logoRadioSzczecin: file(
      relativePath: { eq: "rszczecin-logo-150x150.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    logoLoco: file(relativePath: { eq: "loco-logo-150x150.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    logoScapol: file(relativePath: { eq: "scapol-logo-150x150.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
       modecom: file(relativePath: { eq: "modecom-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    unilever: file(relativePath: { eq: "kolor-unilever.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    interhandler: file(relativePath: { eq: "jcb-interhandler-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    pgnig: file(relativePath: { eq: "pgnig_logotyp.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    mtp: file(relativePath: { eq: "grupa-mtp-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    kompania: file(relativePath: { eq: "kompania-piwowarska-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    sab: file(relativePath: { eq: "sab-miller.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    enpol: file(relativePath: { eq: "enpol.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    dedra: file(relativePath: { eq: "kolor-dedra.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    mfo: file(relativePath: { eq: "mfo-logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    zibi: file(relativePath: { eq: "zibi.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    europart: file(relativePath: { eq: "europart.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    euromasz: file(relativePath: { eq: "euromasz-logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
    ever: file(relativePath: { eq: "kolor-ever.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    digital: file(relativePath: { eq: "dsd.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    kospel: file(relativePath: { eq: "kolor-kospel.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    petring: file(relativePath: { eq: "petring.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    aermec: file(relativePath: { eq: "aermec-logotyp.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    copy: file(relativePath: { eq: "copy-system.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    metal: file(relativePath: { eq: "metal-fach.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    axium: file(relativePath: { eq: "axium-logo-s.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    axtel: file(relativePath: { eq: "kolor-axtel.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    gourmet: file(relativePath: { eq: "gourmet-foods-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    pikselomania: file(relativePath: { eq: "kolor-pikselomania.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    mzuri: file(relativePath: { eq: "mzuri.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    hydrotank: file(relativePath: { eq: "hydrotank.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    yg: file(relativePath: { eq: "yg1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    logo: file(relativePath: { eq: "favicon-new.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    aura: file(relativePath: { eq: "logo-aura.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
    synergius: file(relativePath: { eq: "synergius-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    automico: file(relativePath: { eq: "automicob2b-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: FULL_WIDTH)
      }
    }
    serwis: file(relativePath: { eq: "sp-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    schipolska: file(relativePath: { eq: "logo-schipolska.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    tedmars: file(relativePath: { eq: "tedmars.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    kon: file(
      relativePath: {
        eq: "questy-producent-kompleksowych-rozwiazan-dla-biznesu.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    dts: file(relativePath: { eq: "logo-dts.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    ravenol: file(relativePath: { eq: "ravenol.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    voltea: file(relativePath: { eq: "voltea-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    autoid: file(relativePath: { eq: "autoid-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    mpllogo: file(relativePath: { eq: "mpl-logotyp.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    versim: file(relativePath: { eq: "versim-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    kmt: file(relativePath: { eq: "kmt-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    euromilk: file(relativePath: { eq: "euromilk-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    prokmet: file(relativePath: { eq: "prokmet-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    azzardo: file(relativePath: { eq: "azzardo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    sgarden: file(relativePath: { eq: "sgarden.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    hairtalk: file(relativePath: { eq: "hairtalk.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    signal1: file(relativePath: { eq: "signal1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    pion: file(relativePath: { eq: "pion-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
    sqm: file(relativePath: { eq: "sqm-logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    lcb: file(relativePath: { eq: "lcb.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    frogum: file(relativePath: { eq: "frogum-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    farmtrac: file(relativePath: { eq: "farmtrac.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    wolff: file(relativePath: { eq: "wolff-poligrafia-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: FULL_WIDTH)
      }
    }
    a4: file(relativePath: { eq: "a4.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    demps: file(relativePath: { eq: "demps.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    glover: file(relativePath: { eq: "glover.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    gls: file(relativePath: { eq: "gls2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    krosno: file(relativePath: { eq: "krosno.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    filplast: file(relativePath: { eq: "filplast-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    agrosiec: file(relativePath: { eq: "agrosiec-maszyny.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220, layout: CONSTRAINED)
      }
    }
    nespresso: file(relativePath: { eq: "nespresso-klienci.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
        lublin: file(relativePath: { eq: "targi-lublin-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
            keller: file(relativePath: { eq: "keller-logo.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
                mtb: file(relativePath: { eq: "mtb-logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
                    oknopol: file(relativePath: { eq: "okno-pol-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
            fagron: file(relativePath: { eq: "fagron-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
                loos: file(relativePath: { eq: "logo-loos.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
             dpelogo: file(relativePath: { eq: "dpe-logo-claim.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
             shimpol: file(relativePath: { eq: "shim-pol-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
              intermag: file(relativePath: { eq: "intermag-logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
              sievert: file(relativePath: { eq: "sievert-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
                  splus: file(relativePath: { eq: "s-plus-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
                   meprozet: file(relativePath: { eq: "meprozet-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: FULL_WIDTH)
      }
    }
                       enpoll: file(relativePath: { eq: "enpol-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED)
      }
    }
  }
`;

const GreenBanner = styled.div`
  width: 70%;
  padding: 2rem;
  margin-left: 2rem;
  background-color: hsla(96.9, 82.4%, 33.3%, 0.6);
  border-radius: 10px;
  margin-bottom: 10rem;
  align-self: flex-start;
  text-align: left;

  h1 {
    font-size: 48px;
    text-align: left;
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 32px;
    text-align: left;
    margin-bottom: 1.5rem;
  }

  p {
    max-width: 70%;
  }

  @media (max-width: 767px) {
    width: 90%;
    margin: 2rem auto;

    p {
      max-width: 100%;
    }
  }
`;

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Business Software Producer"
      description="Questy - Poznan software developer for companies ✅ Dedicated solutions • Business management systems • CRM systems • B2B platforms"
      keywords={["Questy"]}/>

    <Hero heroImage={heroImage} justifyContent="flex-end">
      <GreenBanner>
        <h1>Your business wins</h1>

        <h2>Thanks to the synergy of people's talent with our technology</h2>
        <p>
          Nowadays, only a few can survive without a technological advantage.
          That is why we understand our role in building our client's success.
        </p>
      </GreenBanner>
    </Hero>
    <br />
    <br />

    <Container>
      <Columns>
        <div
          style={{
            padding: 20,
          }}
        >
          <h2>Producer of business solutions </h2>
          <br />
          <p>
           We are a Polish company that<strong> has been providing effective IT tools to support business development since 2002</strong>. 
           Our experience and passion for technology allow us to create business solutions that bring tangible benefits to our clients.</p>
           <p>Our clients include both large and recognizable companies as well as smaller, dynamically developing enterprises. 
           Get to know our offer now and join the group of satisfied customers!
          </p>
        </div>
        <div>
          <GatsbyImage
            image={data.logo.childImageSharp.gatsbyImageData}
            style={{
              maxWidth: 200,
              margin: "0 auto",
              marginTop: 30,
            }}
            alt="CRM online for businesses."
          />
        </div>
      </Columns>
    </Container>

        <Container>
      <h2 style={{
        paddingTop: 15,
        paddingBottom: 30,
      }}> Questy in numbers</h2>

      <BenefitBlockWrapper>
        <BenefitBlock
          icon={<FaCalendarDay size="70px" color="#86BC2A" />}
          title="We support business clients since 2002"
        />
        <BenefitBlock
          icon={<FaRegClipboard size="70px" color="#86BC2A" />}
          title="Over 1 000 completed projects"
        />
        <BenefitBlock
          icon={<FaUserTie size="70px" color="#86BC2A" />}
          title="Over 50 technology enthusiasts in the team"
        />
        <BenefitBlock
          icon={<FaLaptop size="70px" color="#86BC2A" />}
          title="Over 100 000 system users"
        />
      </BenefitBlockWrapper>
      </Container>

    <h2 style={{
        paddingTop: 25,
        paddingBottom: 15,
      }}>Our solutions</h2>

    <section className={styles.integrations}>
      <div className="container container--text">
        <br />
        <br />
        <div className={styles.integrations__wrapper}>
          <div style={{
        marginBottom: 40,
      }}>
            <a href="http://www.aurabusiness.com/">
              <GatsbyImage image={data.aura.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div style={{
        marginBottom: 40,
      }}>
            <a href="http://www.synergiuscrm.pl/">
              <GatsbyImage
                image={data.synergius.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div style={{
        marginBottom: 40,
      }}>
            <a href="http://www.automicob2b.pl/">
              <GatsbyImage
                image={data.automico.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div style={{
        marginBottom: 40,
      }}>
            <a href="http://www.serwisplanner.pl/">
              <GatsbyImage
                image={data.serwis.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
        </div>
      </div>
    </section>

    <section
      style={{
        background: "#f9f9f9",
      }}
    >
      <Container>
        <h2
          style={{
            textAlign: "center",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          What our clients say about us
        </h2>
        <Swiper {...params}>
           <div>
            <TestimonialCard
              content="CRM implementation is a difficult process and we were aware that it's not always successful. Good understanding of the client's needs and professional support from Questy at every level of implementation allowed it to be successful. Today, I can proudly say that we are seeing an increase in the efficiency of the sales team, and that's what it was all about. With a clear conscience, I can recommend Questy and their solution, Aura Business CRM."
              logo={
                <GatsbyImage
                  image={data.mtp.childImageSharp.gatsbyImageData}
                  style={{
                    width: "150px",
                  }}
                  alt="Aura Business in Grupa MTP"
                />
              }
              name="Janusz Mazurczak"
              company="Grupa MTP"
            />
          </div>
           <div>
            <TestimonialCard
              content="We have been cooperating with Questy almost from the beginning of our activity. 
              The tool we use through them is our basic work environment. It has the advantage over competing 
              products that it is quickly and efficiently adapted to our new challenges and includes only a strictly 
              defined set of functions necessary in our everyday work. We highly appreciate the direct and efficient 
              form of contact with the dedicated Questy supervisor and we hope for further fruitful cooperation."
              logo={
                <GatsbyImage
                  image={data.dpelogo.childImageSharp.gatsbyImageData}
                  style={{
                    width: "150px",
                  }}
                  alt="Aura Business in Dom Pełnen Energii"
                />
              }
              name="Stanisław Górski"
              company="Dom Pełen Energii"
            />
          </div>
          <div>
            <TestimonialCard
              content="We recommend both the Aura Business system and the cooperation itself. High professionalism, business approach, 
              flexibility in relation to customer requirements confirm our belief that this is one of the best IT systems producers on the Polish market."
              logo={
                <GatsbyImage
                  image={data.interhandler.childImageSharp.gatsbyImageData}
                  style={{
                    width: "150px",
                  }}
                  alt="logo loco"
                />
              }
              name="Maciej Piątkowski"
              company="Interhandler Sp. z o.o."
            />
          </div>
                   <div>
            <TestimonialCard
              content="We are very pleased with the cooperation with Questy. The proposed solutions of the Aura Business system have 
              been smoothly introduced to our company and thanks to that we can now enjoy the improvement of work organization in all 
              departments. We obviously recommend."
              logo={
                <GatsbyImage
                  image={data.loos.childImageSharp.gatsbyImageData}
                  style={{
                    width: "150px",
                  }}
                  alt="Aura Business w Loos Centrum Sp. z o.o. Bosch Group"
                />
              }
              name="Julia Domagała"
              company="CRM in Loos Centrum Bosch Group"
            />
          </div>
          <div>
            <TestimonialCard
              content="Due to the professionalism and commitment of Questy, the implementation process went very smoothly. We are impressed 
              by the vast knowledge and commitment of Questy's employees for making the B2B system fully adapted to both our and our customers' needs."
              logo={
                <GatsbyImage
                  image={data.metal.childImageSharp.gatsbyImageData}
                  style={{
                    width: "150px",
                  }}
                  alt="CRM metalfach"
                />
              }
              name="Marta Marculewicz"
              company="Metal-Fach Technika Grzewcza"
            />
          </div>
          <div>
            <TestimonialCard
              content="As a company, we focus on selling a wide range of products to customers from the B2B channel, therefore we were looking for a tool to 
        improve work and exchange of data. Having the ERP Exact system, which is not a popular system in companies with a similar profile of activity, 
        we had a big problem finding a company providing B2B software with the possibility of integration with our database. Despite our earlier concerns 
        about the possibility of integration, this process went smoothly, enabling us to use the modern functions of the software provided by Questy."
              logo={
                <GatsbyImage
                  image={data.dedra.childImageSharp.gatsbyImageData}
                  style={{
                    width: "105px",
                  }}
                  alt="logo polskie radio szczecin"
                />
              }
              name="Marcin Kruszewski"
              company="Dedra Sp. z o.o."
            />
          </div>
          <div>
            <TestimonialCard
              content="With a clear conscience, I can say that the process of implementing individual modules into our Aura CRM was successful. 
              The Questy company has fought an uphill battle for us many times, but the gentlemen who looked after our system have great knowledge, 
              patience and are well organized. We hope that further cooperation will go as smoothly."
              logo={
                <GatsbyImage
                  image={data.dts.childImageSharp.gatsbyImageData}
                  style={{
                    width: "150px",
                  }}
                  alt="dts system"
                />
              }
              name="Maciej Stram"
              company="DTS SYSTEM Sp. z o.o."
            />
          </div>
          <div>
            <TestimonialCard
              content="The Aura Business Service Management Suite system enabled Enpol to effectively manage the orders carried out for Unilever. 
              The most important effect of the implementation was enabling precise service management and improving communication between companies 
              and their subcontractors using a convenient IT system."
              logo={
                <GatsbyImage
                  image={data.enpol.childImageSharp.gatsbyImageData}
                  style={{
                    width: "150px",
                  }}
                  alt="enpol system service management"
                />
              }
              name=""
              company="Enpol"
            />
          </div>
          <div>
            <TestimonialCard
              content="In connection with the company's development, the company's management decided to implement an IT system to streamline 
              key business processes at Schipolska. The Schipolska company chose the Aura Business system manufacturer from Poznań - the Questy 
              company as its supplier. The implemented Aura Business system plays an important role in the daily work of Schipolska and we are 
              pleased with the cooperation with Questy. I recommend the Aura Business system and the Quests company."
              logo={
                <GatsbyImage
                  image={data.schipolska.childImageSharp.gatsbyImageData}
                  style={{
                    width: "150px",
                  }}
                  alt="schipolska system"
                />
              }
              name="Damian Semba"
              company="Biuro Techniczno-Handlowe Schipolska Sp. z o.o."
            />
          </div>
          <div>
            <TestimonialCard
              content="We started cooperation with Questy with the implementation of the Aura B2B system, which Questy is the producer of. 
              The implementation works lasted several months and from the very beginning were characterized by high commitment on the part of 
              Questy employees. During the work, implementation consultants demonstrated extensive business knowledge and professional advice. 
              Questy offered us a flexible model for implementing the aura B2B system, which did not interfere with the current work of Tedmars 
              and did not interfere with the implementation of the company's objectives."
              logo={
                <GatsbyImage
                  image={data.tedmars.childImageSharp.gatsbyImageData}
                  style={{
                    width: "150px",
                  }}
                  alt="logo loco"
                />
              }
              name="Zbigniew Zieliński"
              company="P.H.U. Tedmars"
            />
          </div>
        </Swiper>
      </Container>
    </section>

    <h2 style={{
        paddingTop: 25,
        paddingBottom: 40,
      }}>We are trusted by</h2>
    
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
          <div>
            <a href="http://www.unilever.pl/">
              <GatsbyImage
                image={data.unilever.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.mtp.pl/">
              <center><GatsbyImage image={data.mtp.childImageSharp.gatsbyImageData} 
              style={{
                    width: "175px",
                  }}/> 
                  </center>
            </a>
          </div>
                    <div>
            <a href="https://gls-group.eu/PL/pl/home">
              <GatsbyImage image={data.gls.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
            <a href="https://filplast.pl/">
            <GatsbyImage
                image={data.filplast.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://dompelenenergii.pl/">
              <center><GatsbyImage image={data.dpelogo.childImageSharp.gatsbyImageData} 
             style={{
                    width: "185px",
                  }} /></center>
            </a>
          </div>
          
        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
        <div>
            <a href="http://www.interhandler.pl/">
               <center><GatsbyImage
                image={data.interhandler.childImageSharp.gatsbyImageData}
              style={{
                    width: "190px",
                  }}/> </center>
            </a>
          </div>
          <div>
            <a href="http://www.kp.pl/">
              <GatsbyImage
                image={data.kompania.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.kospel.pl/">
              <GatsbyImage
                image={data.kospel.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.metalfach.com.pl/">
              <GatsbyImage image={data.metal.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
            <a href="http://www.dedra.pl/">
              <GatsbyImage image={data.dedra.childImageSharp.gatsbyImageData} />
            </a>
          </div>
        </div>
      </div>
    </section>

    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
        <div>
            <a href="https://modecom.com/pl/">
              <GatsbyImage
                image={data.modecom.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.yg-1.pl/">
             <center> <GatsbyImage image={data.yg.childImageSharp.gatsbyImageData} 
              style={{
                    width: "180px",
                  }}/></center>
            </a>
          </div>
          <div>
            <a href="https://www.agro-siec.pl/">
              <GatsbyImage
                image={data.agrosiec.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.zibi.pl/">
              <GatsbyImage image={data.zibi.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
          <a href="https://targi.lublin.pl/pl/">
              <GatsbyImage
                image={data.lublin.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          
        </div>
      </div>
    </section>

    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
        <div>
            <a href="http://www.pgnig.pl/">
              <GatsbyImage image={data.pgnig.childImageSharp.gatsbyImageData} />
            </a>
          </div>
           <div>
            <a href="https://www.nespresso.com/pl/pl/">
              <GatsbyImage
                image={data.nespresso.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
           <div>
            <a href="https://www.shim-pol.pl/">
              <center><GatsbyImage
                image={data.shimpol.childImageSharp.gatsbyImageData}
                style={{
                    width: "160px",
                  }}
              /></center>
            </a>
          </div>
          <div>
            <a href="https://azzardo.com.pl/">
              <GatsbyImage
                image={data.azzardo.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://okno-pol.pl/">
              <GatsbyImage
                image={data.oknopol.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
        <div>
          <a href="http://www.mfo.pl/">
              <GatsbyImage image={data.mfo.childImageSharp.gatsbyImageData} 
              style={{
                    width: "180px",
                  }}/>
            </a>
          </div>
          <div>
            <a href="https://petring.com.pl/pl/">
              <GatsbyImage image={data.petring.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
            <a href="http://www.aermec.com/">
              <GatsbyImage
                image={data.aermec.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://intermag.pl/">
              <GatsbyImage
                image={data.intermag.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://mplpower.pl/">
              <center><GatsbyImage image={data.mpllogo.childImageSharp.gatsbyImageData} 
              style={{
                    width: "170px",
                  }}/></center>
            </a>
          </div>

        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
        <div>
            <a href="http://www.dsd.com.pl/">
              <GatsbyImage
                image={data.digital.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://euromilk.pl/pl/">
              <GatsbyImage
                image={data.euromilk.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://voltea.pl/">
              <GatsbyImage
                image={data.voltea.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://krosno.com.pl/">
              <center><GatsbyImage
                image={data.krosno.childImageSharp.gatsbyImageData}
                style={{
                    width: "190px",
                  }}
              /></center>
            </a>
          </div>
          <div>
            <a href="https://www.dilmah.pl">
              <center><GatsbyImage image={data.gourmet.childImageSharp.gatsbyImageData} 
              style={{
                    width: "170px",
                  }}/></center>
            </a>
          </div>
        
        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
          <div>
            <a href="https://www.versim.pl/">
              <GatsbyImage
                image={data.versim.childImageSharp.gatsbyImageData}
              style={{
                    width: "185px",
                  }}/>
            </a>
          </div>
          <div>
            <a href="http://kmt.com.pl/pl/">
              <GatsbyImage image={data.kmt.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
            <a href="https://www.frogum.com/pl/">
              <GatsbyImage
                image={data.frogum.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://www.farmtrac.pl/">
              <GatsbyImage
                image={data.farmtrac.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://wolfftrading.pl/">
              <GatsbyImage image={data.wolff.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          
        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
          <div>
            <a href="https://sqm.eu/">
             <center> <GatsbyImage image={data.sqm.childImageSharp.gatsbyImageData} 
              style={{
                    width: "150px",
                  }}/></center>
            </a>
          </div>
          <div>
            <a href="hydrotank.eu">
              <GatsbyImage
                image={data.hydrotank.childImageSharp.gatsbyImageData}
                style={{
                    width: "180px",
                  }}
              />
            </a>
          </div>
          <div>
            <a href="https://www.sievert.pl/">
              <GatsbyImage image={data.sievert.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
            <a href="http://www.glover.pl/">
              <GatsbyImage
                image={data.glover.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://pi-on.pl">
              <GatsbyImage
                image={data.pion.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          
        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
          <div>
            <a href="https://e-keller.pl/">
              <GatsbyImage
                image={data.keller.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://mtb-polska.pl/">
              <center><GatsbyImage image={data.mtb.childImageSharp.gatsbyImageData} 
               style={{
                    width: "180px",
                  }}/> </center>
            </a>
          </div>
          <div>
            <a href="https://pl.fagron.com/">
              <GatsbyImage image={data.fagron.childImageSharp.gatsbyImageData} />
            </a>
          </div>
          <div>
            <a href="https://euromasz.pl/">
               <center><GatsbyImage image={data.euromasz.childImageSharp.gatsbyImageData} 
               style={{
                    width: "180px",
                  }}/> </center>
            </a>
          </div>
          <div>
            <a href="https://s-plus.pl">
             <center> <GatsbyImage image={data.splus.childImageSharp.gatsbyImageData} 
               style={{
                    width: "110px",
                  }}/></center>
            </a>
          </div>

        </div>
      </div>
    </section>
    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
          <div>
            <a href="http://www.enpol.com.pl/">
             <center> <GatsbyImage image={data.enpoll.childImageSharp.gatsbyImageData} 
              style={{
                    width: "110px",
                  }}/> </center>
            </a>
          </div>
          <div>
            <a href="http://www.s-garden.pl/">
              <GatsbyImage
                image={data.sgarden.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="https://lcb.leszno.pl/">
             <center> <GatsbyImage image={data.lcb.childImageSharp.gatsbyImageData} 
              style={{
                    width: "150px",
                  }}/></center>
            </a>
          </div>
          <div>
            <a href="http://www.europart.net/">
              <GatsbyImage
                image={data.europart.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
          <div>
            <a href="http://www.copysystem.pl/">
              <center><GatsbyImage image={data.copy.childImageSharp.gatsbyImageData} 
              style={{
                    width: "160px",
                  }}/> </center>
            </a>
          </div>

        </div>
      </div>
    </section>

    <section className={styles.integrations} style={{
          paddingTop: 45,
        }}>
      <div className="container container--text">
        <div className={styles.integrations__wrapper}>
        <div>
            <a href="http://www.prokmet.com/">
              <GatsbyImage
                image={data.prokmet.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>
         <div>
            <a href="http://a4polska.pl/">
              <center><GatsbyImage image={data.a4.childImageSharp.gatsbyImageData} 
              style={{
                    width: "160px",
                  }}/></center>
            </a>
          </div> 
           <div>
            <a href="https://meprozet.com.pl/pl">
              <GatsbyImage image={data.meprozet.childImageSharp.gatsbyImageData} 
              style={{
                    width: "190px",
                  }}/>
            </a>
          </div>
          <div>
            <a href="http://www.pikselomania.pl/">
              <GatsbyImage
                image={data.pikselomania.childImageSharp.gatsbyImageData}
              />
            </a>
          </div>

          <div>
            <a href="http://www.demps.com.pl/">
              <GatsbyImage image={data.demps.childImageSharp.gatsbyImageData}
              style={{
                    width: "170px",
                  }} />
            </a>
          </div>

          
        </div>
      </div>
    </section>

  </Layout>
);

export default IndexPage;
